import { getUserSessionCookie } from '../utils/SessionCookie';

import { BASE_URL } from './constants';

const getUserCompany = async () => {
  const userSessionToken = getUserSessionCookie();
  const res = await fetch(`${BASE_URL}/user/company`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userSessionToken}`
    }
  });

  if (!res.ok) {
    throw new Error('Failed to fetch company');
  }

  return res.json();
};

export { getUserCompany };
