import './styles/App.css';
import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet
} from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import Box from '@mui/material/Box';
import HomePage from './pages/home';
// import GuideMode from './pages/guide';
import {
  useUserContextController,
  setAgentName,
  setIsUserLoading,
  setUser,
  setGoogleCredentials,
  setSlackCredentials,
  setNotionCredentials,
  setCompany,
  Company
} from './context/UserContext';
import { getAgentName, getCredentials, getUser } from './api/userProfile';
import { useCookies } from 'react-cookie';
import Layout from './components/layout';
// import ProfilePage from './pages/profile';
import { GOOGLE_ACCOUNT_TYPE } from './types/userCredentials';
import LoadingComponent from './components/loading';
import {
  setInitialMessageAgent,
  useMessageContextController
} from './context/MessageContext';
// import AgentDashboard from './pages/profile/agent-dashboard';
import DocumentationPage from './pages/documentation/details/';
// import DocumentationListPage from './pages/documentation/list';
import WebTopbar from './components/topbar/WebTopbar';
// import AIFleetPage from './pages/ai-fleet';
import AutomationListPage from './pages/automationV2';
import AutomationForm from './pages/automationV2/form';
import AutomationJobDetails from './pages/automationV2/details';
import AutomationJobInputOutput from './pages/automationV2/input-output';
import { getUserCompany } from './api/company';
import SettingsPage from './pages/settings';
import WorkflowPage from './pages/workflow';
import CreateWorkflowPage from './pages/workflow/create';
import WorkflowDesignStudio from './pages/workflow/designStudio';
import AIWorkersPage from './pages/ai-workers';
import PrivateRoute from './components/privateRoute';
import { AutomationProvider } from './context/AutomationContext';

function App() {
  // Code to test bugsnag. When throwing errors locally, will show error window (this won't show on production)
  // and will redirect app to ErrorComponent.
  // useEffect(() => {
  //   throw new Error("Please try again later");
  // }, [])
  const [cookies, , removeCookie] = useCookies(['user_session_token']);
  const [userContext, userDispatch] = useUserContextController();
  const { user, isUserLoading } = userContext;
  const [, dispatch] = useMessageContextController();
  const { user_session_token } = cookies;

  useEffect(() => {
    if (user_session_token) {
      setIsUserLoading(userDispatch, true);
      Promise.all([
        getUser('DOKAI').then((user) => {
          if (user) {
            setUser(userDispatch, user);
            const accountType = GOOGLE_ACCOUNT_TYPE.WEB_APP;
            return getCredentials(accountType, user.user_id).then(
              (response) => {
                if (response?.credentials?.google) {
                  setGoogleCredentials(userDispatch, {
                    access_token: response.credentials.google.access_token,
                    refresh_token: response.credentials.google.refresh_token
                  });
                }
                if (response?.credentials?.slack) {
                  setSlackCredentials(userDispatch, {
                    access_token: response.credentials.slack.access_token
                  });
                }
                if (response?.credentials?.notion) {
                  setNotionCredentials(userDispatch, {
                    access_token: response.credentials.notion.access_token
                  });
                }
              }
            );
          } else {
            removeCookie('user_session_token', { path: '/' });
            window.location.reload();
          }
        }),
        getAgentName().then((agentName: string) => {
          setAgentName(userDispatch, agentName);
          // To set sender of first message to agentName after fetching.
          setInitialMessageAgent(dispatch, agentName);
        }),
        getUserCompany().then((company: Company) => {
          setCompany(userDispatch, company);
        })
      ])
        .then(() => {})
        .catch(() => {
          removeCookie('user_session_token', { path: '/' });
          window.location.reload();
        })
        .finally(() => {
          setIsUserLoading(userDispatch, false);
        });
    } else {
      setIsUserLoading(userDispatch, false);
    }
    // To remove warning for exhaustive deps
    // eslint-disable-next-line
  }, [user_session_token]);

  if (isUserLoading) {
    return <LoadingComponent />;
  }

  // Todo: set global margin in constant file
  return (
    <ThemeProvider>
      <Box
        sx={(theme) => ({
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100vh',
          overflow: 'hidden',
          backgroundColor: theme.customColors.themeTertiary
        })}
      >
        <Router>
          {user && <WebTopbar />}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              height: 'calc(100% - 48px)',
              width: '100%'
            }}
          >
            <Routes>
              <Route element={<Layout isWebApp />}>
                {/* TODO: Handle private routes instead of redirection in component */}
                <Route path="/home" element={<HomePage isWebApp />} />
                {/* <Route path="/guide" element={<GuideMode isWebApp />} /> */}
                <Route
                  path="/settings"
                  element={
                    <PrivateRoute>
                      <SettingsPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/automation"
                  element={
                    <PrivateRoute>
                      <AutomationListPage />
                    </PrivateRoute>
                  }
                />

                {/* {user?.hasAccessToAutomation && (
                  <Route path="/ai-fleet" element={<AIFleetPage />} />
                )} */}
                <Route
                  path="/automation/create"
                  element={
                    <PrivateRoute>
                      <AutomationForm />
                    </PrivateRoute>
                  }
                />
                <Route
                  element={
                    <AutomationProvider>
                      <Outlet />
                    </AutomationProvider>
                  }
                >
                  <Route
                    path="/automation/job/:jobId"
                    element={
                      <PrivateRoute>
                        <AutomationJobDetails />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/automation/input/:jobId"
                    element={
                      <PrivateRoute>
                        <AutomationJobInputOutput />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/automation/output/:jobId"
                    element={
                      <PrivateRoute>
                        <AutomationJobInputOutput />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="/documentation/details/:documentId"
                  element={<DocumentationPage isWebApp />}
                />
                {/* {user?.isManager && (
                  <Route
                    path="/documentation/list"
                    element={<DocumentationListPage />}
                  />
                )} */}
                {/* <Route
                  path="/ai-workers"
                  element={
                    <PrivateRoute>
                      <AIWorkersPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/workflow"
                  element={
                    <PrivateRoute>
                      <WorkflowPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/workflow/create"
                  element={
                    <PrivateRoute>
                      <CreateWorkflowPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/workflow/:workflowId/design"
                  element={
                    <PrivateRoute>
                      <WorkflowDesignStudio />
                    </PrivateRoute>
                  }
                /> */}
                {/* Catch-all route to redirect to root */}
                <Route path="*" element={<Navigate to="/home" replace />} />
              </Route>
            </Routes>
          </Box>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default App;
