import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { GridColDef } from "@mui/x-data-grid";

interface ColumnMappingModalProps {
  open: boolean;
  onClose: () => void;
  existingColumns: GridColDef[]; 
  detectedColumns: string[];
  onConfirm: (mapping: Record<string, string | null>) => void;
}

const ColumnMappingModal: React.FC<ColumnMappingModalProps> = ({
  open,
  onClose,
  existingColumns,
  detectedColumns,
  onConfirm
}) => {
  const [columnMapping, setColumnMapping] = useState<Record<string, string | null>>({});
  const [targetFields, setTargetFields] = useState<string[]>([]);

  useEffect(() => {
    if (open) {
      const fields = existingColumns
        .filter((c) => c.field !== "actions" && c.field !== "status")
        .map((c) => c.field);
      
      setTargetFields(fields);

      const initialMapping: Record<string, string | null> = {};
      fields.forEach((field) => {
        initialMapping[field] = detectedColumns.includes(field) ? field : null;
      });

      setColumnMapping(initialMapping);
    }
  }, [open, existingColumns, detectedColumns]);

  const handleMappingChange = (expectedInput: string, value: string | null) => {
    setColumnMapping((prev) => ({ ...prev, [expectedInput]: value }));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: '8px'
        }}
      >
        <Typography variant="h6" gutterBottom>
          Map CSV Columns to Expected Inputs
        </Typography>

        {/* Table for better organization */}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Expected Input</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>CSV Column</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {targetFields.map((fieldName) => (
                <TableRow key={fieldName}>
                  <TableCell
                    sx={{ width: '50%' }}
                  >
                    {fieldName}
                  </TableCell>
                  <TableCell
                    sx={{ width: '50%' }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <Select
                        value={columnMapping[fieldName] ?? ''}
                        onChange={(e) => handleMappingChange(fieldName, e.target.value || null)}
                        displayEmpty
                        sx={{ width: '100%' }}
                      >
                        <MenuItem value="">None</MenuItem>
                        {detectedColumns.map((col) => (
                          <MenuItem key={col} value={col}>
                            {col}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box display="flex" justifyContent="space-between" mt={2} gap={1}>
          <Button
            onClick={onClose}
            sx={(theme) => ({
              width: '50%',
              border: `1px solid ${theme.customColors.themePrimary}`,
              backgroundColor: theme.customColors.themeSecondary,
              color: theme.customColors.themePrimary,
              borderRadius: '50px',
              textTransform: 'capitalize'
            })}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={(theme) => ({
              width: '50%',
              border: `1px solid ${theme.customColors.themePrimary}`,
              backgroundColor: theme.customColors.themePrimary,
              color: theme.customColors.themeSecondary,
              borderRadius: '50px',
              textTransform: 'capitalize'
            })}
            onClick={() => onConfirm(columnMapping)}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ColumnMappingModal;
