import { Navigate, useLocation } from "react-router-dom";
import { useUserContextController } from "../../context/UserContext";

interface PrivateRouteProps {
  redirectTo?: string;
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ redirectTo = "/home", children }) => {
  const [userContext] = useUserContextController();
  const { user } = userContext;
  const location = useLocation();

  if (!user) {
    return <Navigate to={redirectTo} state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;