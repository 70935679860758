export interface AutomationJob {
  id: string;
  job_description: string;
  user_id: string;
  creator_name: string;
  create_ts: string;
  start_ts: string;
  end_ts: string;
  status: string;
  extra_data: any;
  workflow_id: string;
  workflow_name: string;
}

export type PythonType =
  | 'str'
  | 'int'
  | 'float'
  | 'bool'
  | 'List[str]'
  | 'List[int]'
  | 'dict'
  | 'file'
  | 'any';

export interface ExpectedInput {
  name: string;
  description: string;
  pyType: PythonType;
}

export interface ExpectedOutput {
  name: string;
  description: string;
  pyType: PythonType;
}

export interface WorkflowBase {
  company_id: string;
  creator_id: string;
  description: string;
  id: string;
  name: string;
}

export interface WorkflowDetail {
  company_id: string;
  creator_id: string;
  creator_name: string;
  description: string;
  id: string;
  input_schema: ExpectedInput[];
  name: string;
  output_schema: ExpectedOutput[];
  create_ts: string;
}

export enum AutomationStatus {
  PENDING = 'pending',
  QUEUEING = 'queueing',
  RUNNING = 'running',
  STOPPED = 'stopped',
  COMPLETED = 'completed',
  FAILED = 'failed'
}
