import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress
} from '@mui/material';
import { Add, ArrowLeft } from '@mui/icons-material';
import Pagination from '../../../components/pagination';
import { downloadInputFile, downloadOutputFile, getAutomationJobV2, getOutputFileUrl } from '../../../api/automationAgent';
import Papa from 'papaparse';
import LoadingTextOverlay from '../../../components/loadingTextOverlay';
import { useSnackbar } from '../../../context/SnackbarContext';
import JobInputOutputDetailSlide from '../../../components/jobInputOutput/jobInputOutputDetailSlide';
import { isFileTypeChecker } from '../../../utils/S3FileChecker';
import { InsertDriveFile } from '@mui/icons-material';
import { useAutomation } from '../../../context/AutomationContext';
import { getWorkflowDetails } from '../../../api/workflow';

const AutomationJobInputOutput: React.FC = () => {
  const { jobId } = useParams();
  const { job, workflow, setJob, setWorkflow } = useAutomation();
  const location = useLocation();
  const isInput = location.pathname.includes('/automation/input');
  const [rows, setRows] = useState<Record<string, any>[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [selectedRowIdx, setSelectedRowIdx] = useState<number | null>(null);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const pageSize = 10;
  const totalPages = Math.ceil(rows.length / pageSize);
  const [isScrolling, setIsScrolling] = useState(false);
  const [mainContentWidth, setMainContentWidth] = useState<number>(60);
  const [isDragging, setIsDragging] = useState(false);
  const buttonText = isInput ? 'Download Input CSV' : 'Download Output CSV';
  const pageTitle = isInput ? 'Job Input' : 'Job Output';

  useEffect(() => {
    setLoading(true);
    if (!job) {
      getAutomationJobV2(jobId || '')
        .then((jobResponse) => {
          setJob(jobResponse);
          if (jobResponse.workflow_id) {
            return getWorkflowDetails(jobResponse.workflow_id);
          }
        })
        .then((workflowResponse) => {
          if (workflowResponse) {
            setWorkflow(workflowResponse);
            fetchAndDisplayFile();
          }
        })
        .catch((error) => {
          showSnackbar('Error fetching job/workflow details: ' + error, 'error');
        });
    } else {
      fetchAndDisplayFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId, isInput]);

  useEffect(() => {
    const handleScroll = (e: Event) => {
      const target = e.target as HTMLElement;
      if (target.scrollTop > 0) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    };

    const mainContent = document.querySelector('.main-content');
    if (mainContent) {
      mainContent.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (mainContent) {
        mainContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        const containerWidth = window.innerWidth;
        const newWidth = (e.clientX / containerWidth) * 100;
        // Limit the width between 30% and 70%
        setMainContentWidth(Math.min(Math.max(newWidth, 30), 70));
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const fetchAndDisplayFile = async () => {
    try {
      const csvBlob = await (isInput ? downloadInputFile(jobId || '') : downloadOutputFile(jobId || ''));
      const csvText = await csvBlob.text();
      Papa.parse(csvText, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const parsedData = results.data as Record<string, any>[];
          setRows(parsedData);
          setLoading(false);
        },
        error: (error: any) => {
          showSnackbar('Error parsing file: ' + error, 'error');
          setLoading(false);
        }
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleDownloadCSV = async () => {
    try {
      setLoadingProcess(true);
      setLoadingMessage('Preparing file to download...');
      const csvBlob = await (isInput ? downloadInputFile(jobId || '') : downloadOutputFile(jobId || ''));
      const url = window.URL.createObjectURL(csvBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${jobId}_${isInput ? 'input' : 'output'}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      showSnackbar('File downloaded', 'success');
    } catch (error) {
      setLoadingProcess(false);
      showSnackbar('Error downloading file: ' + error, 'error');
    } finally {
      setLoadingProcess(false);
    }
  };
  
  const handleDownloadFile = async (s3key: string) => {
    try {
      setLoadingProcess(true);
      setLoadingMessage('Downloading file...');
      const response = await getOutputFileUrl(s3key);
      window.open(response, '_blank');
    } catch(error) {
      setLoadingProcess(false);
      showSnackbar('Error downloading file: ' + error, 'error');
    } finally {
      setLoadingProcess(false);
    }
  }

  const handleBack = () => {
    navigate(`/automation/job/${jobId}`);
  };

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const paginatedRows = rows.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    e.preventDefault();
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress sx={{ color: '#2196f3' }} />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      height="100%"
      sx={{
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <Box
        className="main-content"
        display="flex"
        flexDirection="column"
        height="100%"
        p={1}
        sx={{
          boxSizing: 'border-box',
          overflow: 'auto',
          scrollbarWidth: isScrolling ? 'auto' : 'none',
          '&::-webkit-scrollbar': {
            display: isScrolling ? 'block' : 'none',
            width: '8px'
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#bbb',
            borderRadius: '4px'
          },
          width: selectedRowIdx !== null ? `${mainContentWidth}%` : '100%',
          transition: isDragging ? 'none' : 'width 0.3s ease-in-out',
          '& .MuiTableContainer-root': {
            '&::-webkit-scrollbar': {
              display: isScrolling ? 'block' : 'none',
              height: '8px'
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#bbb',
              borderRadius: '4px'
            },
            scrollbarWidth: isScrolling ? 'auto' : 'none'
          }
        }}
      >
        <LoadingTextOverlay open={loadingProcess} message={loadingMessage} />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
        >
          <Button
            variant="text"
            onClick={handleBack}
            sx={(theme) => ({
              color: theme.customColors.themeNeutralPrimaryColor,
              textTransform: 'capitalize'
            })}
          >
            <ArrowLeft />
            Job Information
          </Button>
          <Typography
            flex={1}
            variant="h6"
            sx={{
              textAlign: 'center',
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)'
            }}
          >
            {pageTitle}
          </Typography>
          <Button
            variant="contained"
            onClick={handleDownloadCSV}
            sx={(theme) => ({
              color: theme.customColors.themeSecondary,
              backgroundColor: theme.customColors.themePrimary,
              textTransform: 'capitalize',
              borderRadius: '8px',
              boxShadow: 'none',
              padding: '5px 15px 5px 10px'
            })}
            disabled={!(rows.length > 0)}
          >
            <Add sx={{ marginRight: '5px' }} /> {buttonText}
          </Button>
        </Box>
        {rows.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(rows[0]).map((key) => (
                    <TableCell key={key} sx={{ fontWeight: 'bold' }}>
                      {key}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedRows.map((row, index) => (
                  <TableRow
                    key={index}
                    onClick={() => setSelectedRowIdx(index)}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                      backgroundColor:
                        index === selectedRowIdx
                          ? 'rgba(33, 150, 243, 0.08)'
                          : 'inherit'
                    }}
                  >
                    {Object.entries(row).map(([key, value], i) => (
                      <TableCell
                        key={i}
                        sx={{
                          maxWidth: '200px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          textAlign: (workflow && isFileTypeChecker(key, isInput ? workflow.input_schema : workflow.output_schema)) ? 'center' : 'inherit',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {(workflow && isFileTypeChecker(key, isInput ? workflow.input_schema : workflow.output_schema)) ? (
                          <Button
                            onClick={() => handleDownloadFile(value)}
                            sx={{ minWidth: 'auto', padding: 0, gap: 1, textTransform: 'capitalize' }}
                            title={value}
                          >
                            <InsertDriveFile sx={(theme) => ({ fontSize: 20, color: theme.customColors.themePrimary })} /> File Download
                          </Button>
                        ) : value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box m={2}>
            <Typography sx={{ fontSize: '1rem', color: 'gray' }}>
              No { isInput ? 'input' : 'output' } available for this job.
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            marginTop: 'auto',
            display: 'flex',
            justifyContent: 'center',
            padding: '10px 0',
            width: 'fit-content',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handleChangePage}
          />
        </Box>
      </Box>
      {selectedRowIdx !== null && (
        <>
          <Box
            sx={{
              width: '4px',
              cursor: 'col-resize',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
            onMouseDown={handleMouseDown}
          />
          <JobInputOutputDetailSlide
            isInput={isInput}
            selectedRow={rows[selectedRowIdx]}
            onClose={() => setSelectedRowIdx(null)}
            handleDownload={(s3Key:string) => handleDownloadFile(s3Key)}
            expectedSchema={isInput ? workflow?.input_schema : workflow?.output_schema}
            sx={{ width: `${100 - mainContentWidth - 0.4}%` }}
          />
        </>
      )}
    </Box>
  );
};

export default AutomationJobInputOutput;
