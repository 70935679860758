import React from 'react';
import { Box, TextField } from '@mui/material';

interface DisabledTextFieldProps {
  label: string;
  value: string | null | undefined;
}

const DisabledTextField: React.FC<DisabledTextFieldProps> = ({ label, value }) => {
  return (
    <Box display="flex" alignItems="center" gap={1} py={1}>
      <Box width="50%">
        <label htmlFor={`disabled-input-${label}`} style={{ whiteSpace: "nowrap" }}>
          {label}
        </label>
      </Box>
      <TextField
        id={`disabled-input-${label}`}
        size="small"
        disabled
        sx={(theme) => ({
          border: theme.customColors?.themeTertiary,
          backgroundColor: theme.customColors?.themeTertiary,
          width: '100%',
          'textarea': {
            scrollbarWidth: 'thin',
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderWidth: "1px",
            },
          },
        })}
        multiline
        maxRows={8}
        value={value}
      />
    </Box>
  );
};

export default DisabledTextField;
