import { Box, CircularProgress, Typography } from '@mui/material';

interface LoadingProps {
  message?: string;
}

const LoadingComponent = ({ message = 'Loading...' }: LoadingProps) => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <CircularProgress size={40} />
      <Typography
        sx={{
          margin: '10px'
        }}
      >
        {message}
      </Typography>
    </Box>
  );
};

export default LoadingComponent;
