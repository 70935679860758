import { BASE_URL } from './constants';
import { getUserSessionCookie } from '../utils/SessionCookie';
import { WorkflowDetail } from '../types/automation';
import { Edge, ReactFlowJsonObject } from '@xyflow/react';

export async function getCompanyWorkflow(
  detail: boolean = false
): Promise<WorkflowDetail[]> {
  const url = `${BASE_URL}/workflow/company?detail=${detail}`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userSessionToken}`
      }
    });

    const data = await response.json();
    const convertedData = data.map((workflow: any) => ({
      ...workflow,
      input_schema: workflow.input_schema?.map((input: any) => ({
        ...input,
        pyType: input.type
      })),
      output_schema: workflow.output_schema?.map((output: any) => ({
        ...output,
        pyType: output.type
      }))
    }));
    return convertedData as WorkflowDetail[];
  } catch (error) {
    console.error('Error getting company workflows:', error);
    throw error;
  }
}

export async function getWorkflowDetails(
  workflowId: string,
  detail: boolean = false
): Promise<WorkflowDetail> {
  const url = `${BASE_URL}/workflow/${workflowId}?detail=${detail}`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userSessionToken}`
      }
    });

    const data = await response.json();
    const convertedData = {
      ...data,
      input_schema: data.input_schema?.map((input: any) => ({
        ...input,
        pyType: input.type
      })),
      output_schema: data.output_schema?.map((output: any) => ({
        ...output,
        pyType: output.type
      }))
    };
    return convertedData as WorkflowDetail;
  } catch (error) {
    console.error('Error getting company workflows:', error);
    throw error;
  }
}

export async function saveWorkflowDag(
  workflowId: string,
  flowData: ReactFlowJsonObject
): Promise<any> {
  const url = `${BASE_URL}/workflow/${workflowId}/dag`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userSessionToken}`
      },
      body: JSON.stringify(flowData)
    });

    if (!response.ok) {
      throw new Error(`Failed to save workflow DAG: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error saving workflow DAG:', error);
    throw error;
  }
}

export async function getWorkflowDag(
  workflowId: string
): Promise<ReactFlowJsonObject> {
  const url = `${BASE_URL}/workflow/${workflowId}/dag`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userSessionToken}`
      }
    });

    if (!response.ok) {
      throw new Error(`Failed to get workflow DAG: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error getting workflow DAG:', error);
    throw error;
  }
}
