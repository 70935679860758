import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from '@mui/material';

interface WelcomeModalProps {
  modalTitle: string;
  modalContent: string;
  open: boolean;
  onClose: () => void;
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({
  modalTitle,
  modalContent,
  open,
  onClose,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ fontWeight: 'bold' }}>{modalTitle}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <Typography>{modalContent}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          sx={(theme) => ({
            width: '80%',
            borderRadius: '40px',
            paddingY: '8px',
            margin: '10px auto',
            backgroundColor: theme.customColors.themePrimary,
            border: `1px solid ${theme.customColors.themePrimary}`,
            textTransform: 'unset',
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '24px',
            color: `${theme.customColors.themeSecondary} !important`,
            ':hover': {
              color: `${theme.customColors.themePrimary} !important`,
              backgroundColor: theme.customColors.themeSecondary
            },
          })}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WelcomeModal;
