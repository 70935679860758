import Box from '@mui/material/Box';
import ProfileNavigations from './ProfileNavigations';
import AppNavigations from './AppNavigations';
import WebNavSidebar from './WebNavSidebar';

interface SidebarComponentProps {
  isWebApp: boolean;
  isManager: boolean;
  hasAccessToAutomation: boolean;
}

const SidebarComponent = (props: SidebarComponentProps) => {
  return (
    <Box
      sx={{
        marginTop: '10px',
        paddingTop: '10px',
        width: props.isWebApp ? '200px' : '50px',
        mx: props.isWebApp ? 1 : 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box'
      }}
    >
      {props.isWebApp ? (
        <WebNavSidebar
          isManager={props.isManager}
          hasAccessToAutomation={props.hasAccessToAutomation}
        />
      ) : (
        <AppNavigations
          isWebApp={props.isWebApp}
          isManager={props.isManager}
          hasAccessToAutomation={props.hasAccessToAutomation}
        />
      )}
      {!props.isWebApp && <ProfileNavigations isWebApp={props.isWebApp} />}
    </Box>
  );
};

export default SidebarComponent;
