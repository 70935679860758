import { Button, CircularProgress, useTheme } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';

interface GoogleSigninButtonProps {
  loading: boolean;
  onClick: () => void;
  sx?: any;
}

const GoogleSigninButton = ({
  loading,
  onClick,
  sx
}: GoogleSigninButtonProps) => {
  const theme = useTheme();

  return (
    <Button
      sx={{
        width: '320px',
        height: '40px',
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
        borderTopLeftRadius: '20px',
        borderBottomLeftRadius: '20px',
        backgroundColor: theme.customColors.themePrimary,
        textTransform: 'unset',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '24px',
        color: `${theme.customColors.themeSecondary} !important`,
        ':hover': {
          color: `${theme.customColors.themePrimary} !important`,
          backgroundColor: theme.customColors.themeSecondary
        },
        pointerEvents: loading ? 'none' : 'auto',
        ...sx
      }}
      disabled={loading}
      onClick={onClick}
      endIcon={
        loading ? (
          <CircularProgress
            sx={{ color: theme.customColors.themeSecondary }}
            size={20}
          />
        ) : null
      }
    >
      <GoogleIcon
        sx={{
          height: '20px',
          width: '20px',
          marginRight: '10px',
          fontSize: '20px',
          alignSelf: 'center'
        }}
      />
      Log in with Google
    </Button>
  );
};

export default GoogleSigninButton;
