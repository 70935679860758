import React from 'react';

import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useUserContextController } from '../../context/UserContext';
import ProfilePage from '../profile';
import UserAccessPage from '../access-control/userAccessPage';

const SettingsPage: React.FC = () => {
  const [userContext] = useUserContextController();
  const { user, company } = userContext;
  const [selectedTab, setSelectedTab] = useState<'profile' | 'access'>(
    'profile'
  );

  if (!user || !company) {
    return <Navigate to="home" replace />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'calc(100vh - 64px)', // Assuming top navbar is 64px
        backgroundColor: '#f5f5f5'
      }}
    >
      {/* Sidebar */}
      <Box
        sx={{
          width: '150px',
          backgroundColor: 'white',
          borderRight: '1px solid #e0e0e0',
          padding: '16px'
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: '24px',
            paddingLeft: '8px',
            fontWeight: 600
          }}
        >
          Settings
        </Typography>

        {/* Navigation Items */}
        <Box
          onClick={() => setSelectedTab('profile')}
          sx={(theme) => ({
            padding: '12px 8px',
            borderRadius: '8px',
            cursor: 'pointer',
            backgroundColor:
              selectedTab === 'profile' ? '#f0f7ff' : 'transparent',
            color:
              selectedTab === 'profile'
                ? theme.customColors.themePrimary
                : 'text.primary',
            '&:hover': {
              backgroundColor: selectedTab === 'profile' ? '#f0f7ff' : '#f5f5f5'
            }
          })}
        >
          <Typography>Profile</Typography>
        </Box>

        {/* <Box
          onClick={() => setSelectedTab('access')}
          sx={(theme) => ({
            padding: '12px 8px',
            borderRadius: '8px',
            cursor: 'pointer',
            marginTop: '8px',
            backgroundColor:
              selectedTab === 'access' ? '#f0f7ff' : 'transparent',
            color:
              selectedTab === 'access'
                ? theme.customColors.themePrimary
                : 'text.primary',
            '&:hover': {
              backgroundColor: selectedTab === 'access' ? '#f0f7ff' : '#f5f5f5'
            }
          })}
        >
          <Typography>Access Control</Typography>
        </Box> */}
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          flex: 1,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {/* {selectedTab === 'profile' ? <ProfilePage /> : <UserAccessPage />} */}
        <ProfilePage />
      </Box>
    </Box>
  );
};

export default SettingsPage;
