import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { AutomationJob } from '../../types/automation';
import { useNavigate } from 'react-router-dom';
import { getAutomationJobsSummaryV2 } from '../../api/automationAgent';
import { statusStyles } from '../../utils/automation';
import { useSnackbar } from '../../context/SnackbarContext';
import AvatarGenerator from '../../components/avatar/avatar';
import { formatLocaleDateTime } from '../../utils/DateTimeFormatter';

const truncateJobId = (jobId: string) => {
  return jobId.slice(0, 8) + '...';
};

const AutomationListPage: React.FC = () => {
  const [page, setPage] = useState(0);
  const [jobs, setJobs] = useState<AutomationJob[]>([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState('');
  const [selectedWorkflow, setSelectedWorkflow] = useState('');

  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const fetchJobs = async () => {
    setLoading(true);
    try {
      const response = await getAutomationJobsSummaryV2(
        page * rowsPerPage,
        rowsPerPage
      );
      setJobs(response.jobs || []);
      setTotalItems(response.total_count || 0);
    } catch (error) {
      setJobs([]);
      setTotalItems(0);
      showSnackbar('Failed to fetch automation jobs: ' + error, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (jobId: string) => {
    navigate(`/automation/job/${jobId}`);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);
  
    return () => clearTimeout(handler);
  }, [searchTerm]);

  const owners = Array.from(new Set(jobs.map(job => job.creator_name)));
  const workflows = Array.from(
    new Set(jobs.map(job => job.workflow_name || job.workflow_id))
  );

  const filteredJobs = jobs.filter(job => {
    const matchesSearch = job.job_description
      .toLowerCase()
      .includes(debouncedSearchTerm.toLowerCase());
  
    const matchesOwner = selectedOwner ? job.creator_name === selectedOwner : true;
  
    const matchesWorkflow = selectedWorkflow 
      ? (job.workflow_name === selectedWorkflow || job.workflow_id === selectedWorkflow)
      : true;
  
    return matchesSearch && matchesOwner && matchesWorkflow;
  });

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        pt={2}
        boxShadow={1}
      >
        <Box flex={1} display="flex" justifyContent="center">
          <TextField
            variant="outlined"
            placeholder="Search..."
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              sx: {
                input: {
                  paddingLeft: '10px'
                }
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <IconButton sx={{ ml: 2 }} onClick={() => setFilterModalOpen(true)}>
            <FilterListIcon />
          </IconButton>
        </Box>
        <Button
          variant="contained"
          sx={(theme) => ({
            color: theme.customColors.themeSecondary,
            backgroundColor: theme.customColors.themePrimary,
            textTransform: 'capitalize',
            borderRadius: '30px',
            boxShadow: 'none'
          })}
          onClick={() => {
            navigate('/automation/create');
          }}
        >
          Create AI Job
        </Button>
      </Box>
      <Box flex={1} overflow="auto">
        <TableContainer
          sx={{
            maxHeight: '100%',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none'
            }
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Job ID</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Creator</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Workflow</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredJobs.map((job) => (
                <TableRow
                  key={job.id}
                  hover
                  onClick={() => handleRowClick(job.id)}
                  sx={(theme) => ({
                    '&:hover': {
                      backgroundColor: theme.customColors.themeTertiary,
                      cursor: 'pointer'
                    },
                    '&:nth-of-type(even)': {
                      backgroundColor: '#fcfcfc'
                    }
                  })}
                >
                  <TableCell>{truncateJobId(job.id)}</TableCell>
                  <TableCell>
                    <AvatarGenerator fullname={job.creator_name} />{job.creator_name}
                  </TableCell>
                  <TableCell>{job.job_description}</TableCell>
                  <TableCell>{job.workflow_name || job.workflow_id}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        backgroundColor:
                          statusStyles[job.status]?.backgroundColor || '#e0e0e0',
                        color: statusStyles[job.status]?.color || '#000',
                        padding: '4px 12px',
                        borderRadius: '16px',
                        display: 'inline-block',
                        fontSize: '0.875rem',
                        textTransform: 'capitalize'
                      }}
                    >
                      {job.status}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'left' }}>
                    {formatLocaleDateTime(job.create_ts)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        padding={2}
        boxShadow={1}
      >
        <TablePagination
          component="div"
          count={totalItems}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Modal open={filterModalOpen} onClose={() => setFilterModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px'
          }}
        >
          <Typography variant="h6" gutterBottom>
            Filter Jobs
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel id="owner-select-label">Owner</InputLabel>
            <Select
              labelId="owner-select-label"
              value={selectedOwner}
              onChange={(e) => setSelectedOwner(e.target.value)}
              label="Owner"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {owners.map((owner) => (
                <MenuItem key={owner} value={owner}>
                  {owner}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="workflow-select-label">Workflow Type</InputLabel>
            <Select
              labelId="workflow-select-label"
              value={selectedWorkflow}
              onChange={(e) => setSelectedWorkflow(e.target.value)}
              label="Workflow Type"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {workflows.map((workflow) => (
                <MenuItem key={workflow} value={workflow}>
                  {workflow}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Modal>
    </Box>
  );
};

export default AutomationListPage;
