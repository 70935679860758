import React from 'react';
import { Slide, Card, Box, Typography, Button } from '@mui/material';
import { Close, ContentCopy } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import { isFileTypeChecker } from '../../utils/S3FileChecker';
import { InsertDriveFile } from '@mui/icons-material';
import { useSnackbar } from '../../context/SnackbarContext';
import { ExpectedInput, ExpectedOutput } from '../../types/automation';

interface JobInputOutputDetailSlideProps {
  isInput: boolean;
  selectedRow: Record<string, any> | null;
  onClose: () => void;
  expectedSchema?: ExpectedInput[] | ExpectedOutput[] | null | undefined;
  handleDownload: (s3Key: string) => void;
  sx?: React.CSSProperties;
}

const JobInputOutputDetailSlide: React.FC<JobInputOutputDetailSlideProps> = ({
  isInput,
  selectedRow,
  onClose,
  expectedSchema,
  handleDownload,
  sx
}) => {
  const { showSnackbar } = useSnackbar();
  return (
    <Slide
      direction="left"
      in={selectedRow !== null}
      mountOnEnter
      unmountOnExit
    >
      <Card
        sx={{
          width: '40%',
          height: '100%',
          position: 'absolute',
          right: 0,
          top: 0,
          borderRadius: '16px 0px 0px 16px',
          boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.1)',
          p: 3,
          boxSizing: 'border-box',
          overflow: 'auto',
          ...sx
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Typography variant="h6">{ isInput ? 'Input' : 'Output' } Details</Typography>
          <Button onClick={onClose} sx={(theme) => ({ minWidth: 'auto', p: 1, color: theme.customColors.themePrimary })}>
            <Close />
          </Button>
        </Box>

        {selectedRow !== null &&
          Object.entries(selectedRow).map(([key, value]) => (
            <Box key={key} mb={1}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                gutterBottom
                sx={(theme) => ({ fontWeight: 'bold', color: theme.customColors.themeNeutralPrimaryColor })}
              >
                {key}
              </Typography>
              <Box
                position="relative"
                onMouseEnter={(e) => {
                  if (!isFileTypeChecker(key, expectedSchema)) {
                    e.currentTarget
                      .querySelector('button')
                      ?.style.setProperty('opacity', '1')
                  }
                }}
                onMouseLeave={(e) => {
                  if (!isFileTypeChecker(key, expectedSchema)) {
                    e.currentTarget
                      .querySelector('button')
                      ?.style.setProperty('opacity', '0')
                  }
                }}
                onClick={() => {
                  if (isFileTypeChecker(key, expectedSchema)) {
                    handleDownload(value)
                  } else {
                    navigator.clipboard.writeText(String(value));
                    showSnackbar("Text copied to clipboard", "success");
                  }
                }}
                p={1}
                sx={(theme) => ({
                  cursor: 'pointer',
                  '&:hover': { bgcolor: theme.customColors.themeTertiary }
                })}
              >
                { isFileTypeChecker(key, expectedSchema) ? (
                  <Button
                    sx={{ minWidth: 'auto', padding: 0, gap: 1, textTransform: 'capitalize' }}
                    title={value}
                  >
                    <InsertDriveFile sx={(theme) => ({ fontSize: 20, color: theme.customColors.themePrimary })} /> File Download
                  </Button>
                ) : <ReactMarkdown>{String(value)}</ReactMarkdown>}
                {
                  !isFileTypeChecker(key, expectedSchema) && (
                  <Button
                    size="small"
                    sx={(theme) => ({
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      opacity: 0,
                      transition: 'opacity 0.2s',
                      color: theme.customColors.themePrimary,
                      minWidth: 'auto',
                      p: 0.5
                    })}
                  >
                    <ContentCopy fontSize="small" />
                  </Button>)
                }
              </Box>
            </Box>
          ))}
      </Card>
    </Slide>
  );
};

export default JobInputOutputDetailSlide;
