import { UserCredentials } from '../types/userCredentials';
import { BASE_URL } from './constants';
import { TokenResponse } from '../types/refreshToken';
import { getUserSessionCookie } from '../utils/SessionCookie';

const getUser = async (appName: string) => {
  const userSessionToken = getUserSessionCookie();
  const res = await fetch(`${BASE_URL}/user`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-app-name': appName,
      Authorization: `Bearer ${userSessionToken}`
    }
  });

  if (!res.ok) {
    switch (res.status) {
      case 404:
        return null;
      default:
        return null;
    }
  }

  return res.json();
};

const getAgentName = async () => {
  const userSessionToken = getUserSessionCookie();
  const res = await fetch(`${BASE_URL}/user/ai_agent`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userSessionToken}`
    }
  });

  if (!res.ok) {
    switch (res.status) {
      case 404:
        return null;
      default:
        return null;
    }
  }

  return res.json();
};

const login = async (token: string, appName: string) => {
  const res = await fetch(`${BASE_URL}/signin/oauth`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-app-name': appName
    },
    body: JSON.stringify({ token })
  });

  if (!res.ok) {
    const errorData = await res.json().catch(() => ({}));
    throw new Error(errorData.detail || `Login failed with status ${res.status}`);
  }

  return res.json();
};

const setCredentials = async (
  accountType: string,
  userCredentials: UserCredentials
) => {
  const res = await fetch(`${BASE_URL}/user/credentials/${accountType}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userCredentials)
  });

  return res.json();
};

const getCredentials = async (accountType: string, userId: string) => {
  const res = await fetch(
    `${BASE_URL}/user/credentials/${accountType}?user_id=${userId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  return res.json();
};

const refreshGoogleToken = async (
  refreshToken: string
): Promise<TokenResponse> => {
  return fetch(`${BASE_URL}/api/refresh-google-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ refresh_token: refreshToken })
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((errorData) => {
          throw new Error(
            errorData.detail ||
              `Error ${response.status}: Failed to refresh Google token`
          );
        });
      }
      return response.json();
    })
    .then((data) => ({
      access_token: data.access_token,
      refresh_token: data.refresh_token
    }));
};

const getGoogleToken = async (): Promise<TokenResponse> => {
  const userSessionToken = getUserSessionCookie();
  const res = await fetch(`${BASE_URL}/user/google-token`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userSessionToken}`
    }
  });

  if (!res.ok) {
    return res.json().then((errorData) => {
      throw new Error(
        errorData.detail || `Error ${res.status}: Failed to get Google token`
      );
    });
  }

  const data = await res.json();
  return {
    access_token: data.access_token
  };
};

export {
  getUser,
  getAgentName,
  login,
  setCredentials,
  getCredentials,
  refreshGoogleToken,
  getGoogleToken
};
