import { ExpectedInput, ExpectedOutput } from "../types/automation";

export const isS3Link = (url: string): boolean => {
  const s3Patterns = [
    /^s3:\/\/([a-z0-9.-]+)\/(.+)$/,
    /^https:\/\/([a-z0-9.-]+)\.s3\.amazonaws\.com\/(.+)$/,
    /^https:\/\/s3\.amazonaws\.com\/([a-z0-9.-]+)\/(.+)$/,
    /^https:\/\/bucketeer-[a-zA-Z0-9-]+\.s3\.amazonaws\.com\/(.+)$/,
    /^https:\/\/([a-z0-9-]+-bucketeer)\.s3\.amazonaws\.com\/(.+)$/
  ];

  return s3Patterns.some(pattern => pattern.test(url));
}

export const isFileTypeChecker = (fieldKey: string, schema: ExpectedInput[] | ExpectedOutput[] | null | undefined) => {
  if (!schema) return false;
  const expectedField = schema.find(field => field.name === fieldKey);
  if (expectedField && expectedField.pyType === 'file') {
    return true;
  }
  return false;
}
