import {
  Button,
  CircularProgress,
  TextField,
  useTheme,
  Box
} from '@mui/material';
import { useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';

interface EmailLoginTileProps {
  loading: boolean;
  onSubmit: (email: string) => void;
  sx?: any;
}

const EmailLoginTile = ({ loading, onSubmit, sx }: EmailLoginTileProps) => {
  const theme = useTheme();
  const [email, setEmail] = useState('');

  const handleSubmit = () => {
    if (email) {
      onSubmit(email);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '320px'
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={loading}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '20px',
            backgroundColor: theme.palette.background.paper,
            '&:hover fieldset': {
              borderColor: theme.customColors.themePrimary
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.customColors.themePrimary
            }
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: theme.customColors.themePrimary
          }
        }}
      />

      <Button
        variant="contained"
        sx={{
          height: '40px',
          borderRadius: '20px',
          backgroundColor: theme.customColors.themePrimary,
          textTransform: 'none',
          fontWeight: '600',
          fontSize: '14px',
          lineHeight: '24px',
          color: `${theme.customColors.themeSecondary} !important`,
          boxShadow: 'none',
          transition: 'all 0.3s ease',
          ':hover': {
            color: `${theme.customColors.themePrimary} !important`,
            backgroundColor: theme.customColors.themeSecondary,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
          },
          pointerEvents: loading ? 'none' : 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          ...sx
        }}
        disabled={loading || !email}
        onClick={handleSubmit}
      >
        {loading ? (
          <CircularProgress
            sx={{ color: theme.customColors.themeSecondary }}
            size={20}
          />
        ) : (
          <>
            <EmailIcon sx={{ fontSize: 20 }} />
            Login with Email
          </>
        )}
      </Button>
    </Box>
  );
};

export default EmailLoginTile;
