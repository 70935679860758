import { Box, Button, Typography } from '@mui/material';
import { ArrowLeft } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';

interface PageTopbarProps {
  title: string;
  backButtonText?: string;
  onBack?: () => void;
  rightComponent?: React.ReactNode;
}

export const PageTopbar = ({
  title,
  backButtonText,
  onBack,
  rightComponent
}: PageTopbarProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
    >
      {onBack && (
        <Button
          variant="text"
          onClick={onBack}
          sx={(theme) => ({
            color: theme.customColors.themeNeutralPrimaryColor,
            textTransform: 'capitalize'
          })}
        >
          <ArrowLeft />
          {backButtonText || 'Back'}
        </Button>
      )}

      <Typography
        flex={1}
        variant="h6"
        sx={{
          textAlign: 'center',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      >
        {title}
      </Typography>
      {rightComponent}
    </Box>
  );
};
