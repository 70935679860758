import { useState } from 'react';
import { Box, Typography, Menu, MenuItem } from '@mui/material';
import { useUserContextController } from '../../context/UserContext';
import DokaiLogo from '../../assets/dokai-logo.svg';
import LogoutButton from '../logout/LogoutButton';
import AvatarGenerator from '../avatar/avatar';

const WebTopbar = () => {
  const [userContext] = useUserContextController();
  const { user } = userContext;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        pt: 1,
        px: 1
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
        <img src={DokaiLogo} alt="Dokai Logo" style={{ height: '32px' }} />
      </Box>

      <Box
        onClick={handleClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          cursor: 'pointer'
        }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Typography variant="body1">{user?.fullName}</Typography>
        <AvatarGenerator
          fullname={user?.fullName || ''}
          imageUrl={user?.profilePictureUrl}
          size={40}
        />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '& .MuiList-root': {
            paddingTop: '2px !important',
            paddingBottom: '2px !important',
            minWidth: '200px'
          }
        }}
      >
        <MenuItem key="logout" sx={{ padding: '0px' }}>
          <LogoutButton />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default WebTopbar;
