import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button
} from '@mui/material';
import {
  getAutomationJobV2,
  downloadOutputFile,
  downloadInputFile,
  getOutputFileUrl
} from '../../../api/automationAgent';
import { AutomationStatus } from '../../../types/automation';
import { statusStyles } from '../../../utils/automation';
import { Add } from '@mui/icons-material';
import Papa from 'papaparse';
import { useSnackbar } from '../../../context/SnackbarContext';
import LoadingTextOverlay from '../../../components/loadingTextOverlay';
import {
  formatLocaleDateTime,
  formatTimeDelta
} from '../../../utils/DateTimeFormatter';
import { useAutomation } from '../../../context/AutomationContext';
import { isFileTypeChecker } from '../../../utils/S3FileChecker';
import { InsertDriveFile } from '@mui/icons-material';
import { getWorkflowDetails } from '../../../api/workflow';
import { PageTopbar } from '../../../components/topbar/PageTopbar';

const AutomationJobDetails = () => {
  const { jobId } = useParams();
  const { job, workflow, setJob, setWorkflow } = useAutomation();
  const [loading, setLoading] = useState(true);
  const [inputRows, setInputRows] = useState<Record<string, any>[]>([]);
  const [outputRows, setOutputRows] = useState<Record<string, any>[]>([]);
  const [inputLoading, setInputLoading] = useState(false);
  const [outputLoading, setOutputLoading] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getAutomationJobV2(jobId || '')
      .then((response) => {
        setJob(response);
        if (response.workflow_id) {
          getWorkflowDetails(response.workflow_id).then((response) => {
            setWorkflow(response);
          });
        }
        fetchAndDisplayInputFile();
        if (response.status === AutomationStatus.COMPLETED) {
          fetchAndDisplayOutputFile();
        }
      })
      .catch((error) => {
        showSnackbar('Error fetching job details: ' + error, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  const fetchAndDisplayInputFile = async () => {
    setInputLoading(true);
    try {
      const csvBlob = await downloadInputFile(jobId || '');
      const csvText = await csvBlob.text();
      Papa.parse(csvText, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const parsedData = results.data as Record<string, any>[];
          setInputRows(parsedData.slice(0, 5));
        },
        error: (error: any) => {
          showSnackbar('Error parsing job input: ' + error, 'error');
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setInputLoading(false);
    }
  };

  const fetchAndDisplayOutputFile = async () => {
    setOutputLoading(true);
    try {
      const csvBlob = await downloadOutputFile(jobId || '');
      const csvText = await csvBlob.text();
      Papa.parse(csvText, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const parsedData = results.data as Record<string, any>[];
          setOutputRows(parsedData.slice(0, 5));
        },
        error: (error: any) => {
          showSnackbar('Error parsing job output: ' + error, 'error');
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setOutputLoading(false);
    }
  };

  const handleDownloadCSV = async (isInput: boolean) => {
    try {
      setLoadingProcess(true);
      setLoadingMessage('Preparing file to download...');
      const csvBlob = await (isInput
        ? downloadInputFile(jobId || '')
        : downloadOutputFile(jobId || ''));
      const url = window.URL.createObjectURL(csvBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${jobId}_${isInput ? 'input' : 'output'}.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      showSnackbar('File downloaded', 'success');
    } catch (error) {
      setLoadingProcess(false);
      showSnackbar('Error downloading file: ' + error, 'error');
    } finally {
      setLoadingProcess(false);
    }
  };

  const handleDownloadFile = async (s3key: string) => {
    try {
      setLoadingProcess(true);
      setLoadingMessage('Downloading file...');
      const response = await getOutputFileUrl(s3key);
      window.open(response, '_blank');
    } catch (error) {
      setLoadingProcess(false);
      showSnackbar('Error downloading file: ' + error, 'error');
    } finally {
      setLoadingProcess(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <CircularProgress sx={{ color: '#2196f3' }} />
      </Box>
    );
  }

  if (!job) {
    return (
      <Box sx={{ p: 3, height: '100%' }}>
        <Typography variant="h6" color="error">
          Job not found
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      p={1}
      sx={{
        overflowY: 'auto'
      }}
    >
      <PageTopbar
        title="Automation Job Details"
        backButtonText="Back"
        onBack={() => navigate('/automation')}
      />
      <Box
        sx={{
          p: 3,
          height: '100%',
          boxSizing: 'border-box',
          overflow: 'auto',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        <LoadingTextOverlay open={loadingProcess} message={loadingMessage} />
        <Box
          sx={{
            maxWidth: '800px',
            margin: '0 auto'
          }}
        >
          {/* Job ID, Description, and Status */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 4,
              flexWrap: 'wrap'
            }}
          >
            <Box
              sx={(theme) => ({
                p: 2,
                borderRadius: 1,
                '&:hover': { bgcolor: theme.customColors.themeTertiary }
              })}
            >
              <Typography
                variant="subtitle2"
                sx={(theme) => ({
                  fontWeight: 600,
                  '&:hover': { bgcolor: theme.customColors.themeTertiary }
                })}
              >
                Job ID
              </Typography>
              <Typography sx={{ fontSize: '1.1rem' }}>{job.id}</Typography>
            </Box>

            <Box
              sx={(theme) => ({
                p: 2,
                borderRadius: 1,
                '&:hover': { bgcolor: theme.customColors.themeTertiary }
              })}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 600
                }}
              >
                Creator
              </Typography>
              <Typography sx={{ fontSize: '1.1rem' }}>
                {job.creator_name}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={(theme) => ({
              p: 2,
              borderRadius: 1,
              '&:hover': { bgcolor: theme.customColors.themeTertiary }
            })}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
              Description
            </Typography>
            <Typography sx={{ fontSize: '1.1rem' }}>
              {job.job_description}
            </Typography>
          </Box>

          <Box
            sx={(theme) => ({
              p: 2,
              borderRadius: 1,
              '&:hover': { bgcolor: theme.customColors.themeTertiary },
              display: 'flex',
              alignItems: 'center',
              gap: 4,
              flexWrap: 'wrap'
            })}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                Status
              </Typography>
              <Typography
                sx={{
                  display: 'inline-block',
                  px: 2,
                  py: 0.5,
                  borderRadius: 1,
                  textTransform: 'capitalize',
                  fontSize: '0.9rem',
                  fontWeight: 500,
                  ...statusStyles[job.status]
                }}
              >
                {job.status}
              </Typography>
            </Box>

            {job.extra_data?.number_of_actions && (
              <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  Total Actions
                </Typography>
                <Typography sx={{ fontSize: '1.1rem' }}>
                  {job.extra_data.number_of_actions}
                </Typography>
              </Box>
            )}
          </Box>

          {/* Job start-end */}
          <Box
            sx={(theme) => ({
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 10,
              flexWrap: 'wrap'
            })}
          >
            <Box
              sx={(theme) => ({
                p: 2,
                borderRadius: 1,
                '&:hover': { bgcolor: theme.customColors.themeTertiary }
              })}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                Start Time
              </Typography>
              <Typography sx={{ fontSize: '1.1rem' }}>
                {job.start_ts ? formatLocaleDateTime(job.start_ts) : '-'}
              </Typography>
            </Box>

            <Box
              sx={(theme) => ({
                p: 2,
                borderRadius: 1,
                '&:hover': { bgcolor: theme.customColors.themeTertiary }
              })}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                End Time
              </Typography>
              <Typography sx={{ fontSize: '1.1rem' }}>
                {job.end_ts ? formatLocaleDateTime(job.end_ts) : '-'}
              </Typography>
            </Box>

            {job.start_ts && job.end_ts && (
              <Box
                sx={(theme) => ({
                  p: 2,
                  borderRadius: 1,
                  '&:hover': { bgcolor: theme.customColors.themeTertiary }
                })}
              >
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  Time Taken
                </Typography>
                <Typography sx={{ fontSize: '1.1rem' }}>
                  {formatTimeDelta(job.start_ts, job.end_ts)}
                </Typography>
              </Box>
            )}
          </Box>

          {/* Job Input Table */}
          <Box
            sx={(theme) => ({
              p: 2,
              borderRadius: 1,
              '&:hover': { bgcolor: theme.customColors.themeTertiary }
            })}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
              Job Input
            </Typography>
            {inputLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                py={2}
              >
                <CircularProgress size={24} />
              </Box>
            ) : inputRows.length > 0 ? (
              <TableContainer
                sx={{
                  scrollbarWidth: 'thin'
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Object.keys(inputRows[0]).map((key) => (
                        <TableCell key={key} sx={{ fontWeight: 'bold' }}>
                          {key}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {inputRows.map((row, index) => (
                      <TableRow key={index}>
                        {Object.entries(row).map(([key, value], i) => (
                          <TableCell
                            key={i}
                            sx={{
                              maxWidth: '200px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              textAlign: isFileTypeChecker(
                                key,
                                workflow?.input_schema
                              )
                                ? 'center'
                                : 'inherit',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {isFileTypeChecker(key, workflow?.input_schema) ? (
                              <Button
                                onClick={() => handleDownloadFile(value)}
                                sx={{
                                  minWidth: 'auto',
                                  padding: 0,
                                  gap: 1,
                                  textTransform: 'capitalize'
                                }}
                                title={value}
                              >
                                <InsertDriveFile
                                  sx={(theme) => ({
                                    fontSize: 20,
                                    color: theme.customColors.themePrimary
                                  })}
                                />{' '}
                                File Download
                              </Button>
                            ) : (
                              value
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography sx={{ fontSize: '1rem', color: 'gray' }}>
                No job input available for this job.
              </Typography>
            )}

            <Box display="flex" flexDirection="row" justifyContent="end" mt={1}>
              <Button
                variant="contained"
                onClick={() => handleDownloadCSV(true)}
                sx={(theme) => ({
                  color: theme.customColors.themeSecondary,
                  backgroundColor: theme.customColors.themePrimary,
                  textTransform: 'capitalize',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  padding: '5px 15px 5px 10px',
                  alignSelf: 'center'
                })}
                disabled={!(inputRows.length > 0)}
              >
                <Add sx={{ marginRight: '5px' }} /> Download Input CSV
              </Button>
              {inputRows.length > 0 && (
                <Button
                  variant="text"
                  sx={(theme) => ({
                    color: theme.customColors.themePrimary,
                    textTransform: 'capitalize',
                    boxShadow: 'none',
                    padding: 0,
                    alignSelf: 'center',
                    marginLeft: '16px',
                    fontWeight: 'bold'
                  })}
                  onClick={() => navigate(`/automation/input/${jobId}`)}
                >
                  See More...
                </Button>
              )}
            </Box>
          </Box>

          {/* Job Output Table */}
          <Box
            sx={(theme) => ({
              mb: 3,
              p: 2,
              borderRadius: 1,
              '&:hover': { bgcolor: theme.customColors.themeTertiary }
            })}
          >
            <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>
              Job Output
            </Typography>
            {outputLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                py={2}
              >
                <CircularProgress size={24} />
              </Box>
            ) : outputRows.length > 0 ? (
              <TableContainer
                sx={{
                  scrollbarWidth: 'thin'
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {Object.keys(outputRows[0]).map((key) => (
                        <TableCell key={key} sx={{ fontWeight: 'bold' }}>
                          {key}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {outputRows.map((row, index) => (
                      <TableRow key={index}>
                        {Object.entries(row).map(([key, value], i) => (
                          <TableCell
                            key={i}
                            sx={{
                              maxWidth: '200px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              textAlign: isFileTypeChecker(
                                key,
                                workflow?.output_schema
                              )
                                ? 'center'
                                : 'inherit',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {isFileTypeChecker(key, workflow?.output_schema) ? (
                              <Button
                                onClick={() => handleDownloadFile(value)}
                                sx={{
                                  minWidth: 'auto',
                                  padding: 0,
                                  gap: 1,
                                  textTransform: 'capitalize'
                                }}
                                title={value}
                              >
                                <InsertDriveFile
                                  sx={(theme) => ({
                                    fontSize: 20,
                                    color: theme.customColors.themePrimary
                                  })}
                                />{' '}
                                File Download
                              </Button>
                            ) : (
                              value
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography sx={{ fontSize: '1rem', color: 'gray' }}>
                No output available for this job.
              </Typography>
            )}

            <Box display="flex" flexDirection="row" justifyContent="end" mt={1}>
              <Button
                variant="contained"
                onClick={() => handleDownloadCSV(false)}
                sx={(theme) => ({
                  color: theme.customColors.themeSecondary,
                  backgroundColor: theme.customColors.themePrimary,
                  textTransform: 'capitalize',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  padding: '5px 15px 5px 10px',
                  alignSelf: 'center'
                })}
                disabled={!(outputRows.length > 0)}
              >
                <Add sx={{ marginRight: '5px' }} /> Download Output CSV
              </Button>
              {outputRows.length > 0 && (
                <Button
                  variant="text"
                  sx={(theme) => ({
                    color: theme.customColors.themePrimary,
                    textTransform: 'capitalize',
                    boxShadow: 'none',
                    padding: 0,
                    alignSelf: 'center',
                    marginLeft: '16px',
                    fontWeight: 'bold'
                  })}
                  onClick={() => navigate(`/automation/output/${jobId}`)}
                >
                  See More...
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AutomationJobDetails;
