export const formatLocaleDateTime = (timestamp: string | number | Date) => {
  const date = new Date(timestamp);

  const formattedDate = date.toLocaleDateString(undefined, {
    month: 'short',
    day: '2-digit'
  });

  const formattedTime = date.toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit'
  });

  return `${formattedDate} ${formattedTime}`;
};

export const formatTimeDelta = (startISO: string, endISO: string): string => {
  const startTimestamp = new Date(startISO).getTime();
  const endTimestamp = new Date(endISO).getTime();
  
  let delta = Math.abs(endTimestamp - startTimestamp) / 1000;

  const timeUnits = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  const result: string[] = [];

  for (const unit of timeUnits) {
    if (delta >= unit.seconds) {
      const value = Math.floor(delta / unit.seconds);
      delta -= value * unit.seconds;
      result.push(`${value} ${unit.label}${value > 1 ? "s" : ""}`);
    }
  }

  return result.join(" ");
}
