import { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { isChromeExtApp, isSopai } from '../../utils/chromeHelper';
import {
  GOOGLE_AUTH_B2B_BASE_URL,
  GOOGLE_AUTH_BASE_URL
} from '../../api/constants';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import DokaiIcon from '../../assets/dokai-icon.svg';
import SopaiIcon from '../../assets/sopai-logo-transparent.svg';
import { login, setCredentials } from '../../api/userProfile';
import { GOOGLE_ACCOUNT_TYPE } from '../../types/userCredentials';
import { useCookies } from 'react-cookie';
import { useSnackbar } from '../../context/SnackbarContext';
import {
  setGoogleCredentials,
  useUserContextController
} from '../../context/UserContext';
import { getPublicToken } from '../../api/environmentVariables';
import { URL_PATHNAMES } from '../../components/layout/index';
import Bugsnag from '@bugsnag/js';
import { ExtensionName, getExtensionName } from '../../utils/ManifestChecker';
import GoogleSigninButton from './GoogleSigninButton';
import MicrosoftSigninButton from './MicrosoftSigninButton';
import WelcomeModal from '../modal/WelcomeModal';
import EmailLoginTile from './EmailLoginTile';

const LoginComponent = () => {
  const theme = useTheme();
  const [, setCookie] = useCookies(['user_session_token']);
  const [, userDispatch] = useUserContextController();
  const [loading, setLoading] = useState(false);
  const [unregisteredChromeAppId, setUnregisteredChromeAppId] = useState(false);
  const { showSnackbar } = useSnackbar();
  const extensionName = !isChromeExtApp() ? null : getExtensionName();
  const [openModal, setOpenModal] = useState(false);
  const icon = !isSopai() ? DokaiIcon : SopaiIcon;

  const onSignIn = (token: string) => {
    if (token) {
      login(token, isChromeExtApp() ? String(extensionName) : 'DOKAI')
        .then((loginData: any) => {
          const userData = loginData.user;
          const sessionData = loginData.session;
          const accountType = isChromeExtApp()
            ? GOOGLE_ACCOUNT_TYPE.EXTENSION
            : GOOGLE_ACCOUNT_TYPE.WEB_APP;
          if (sessionData.session_token) {
            const userCredentials = {
              user_id: String(userData.user_id),
              credentials: {
                google: {
                  access_token: sessionData.google_oauth_access_token,
                  refresh_token: sessionData.google_oauth_refresh_token
                }
              }
            };

            setCredentials(accountType, userCredentials)
              .then(() => {
                setCookie('user_session_token', sessionData.session_token, {
                  path: '/'
                });
                setGoogleCredentials(userDispatch, {
                  access_token: sessionData.google_oauth_access_token,
                  refresh_token: sessionData.google_oauth_refresh_token
                });
                showSnackbar('Login Successful', 'success');
              })
              .catch(() => {
                showSnackbar('Login failed', 'error');
                setLoading(false);
                Bugsnag.notify('Failed to set user credentials on login');
              });
          }
        })
        .catch((error: any) => {
          setLoading(false);

          const errorMessage = error?.message || 'Login failed';
          if (errorMessage.includes('No affiliated organization found')) {
            setOpenModal(true);
          } else {
            showSnackbar(errorMessage, 'error');
            Bugsnag.notify(`User failed to login: ${errorMessage}`);
          }
        });
    } else {
      setLoading(false);
      showSnackbar('Failed to login, token is NULL', 'error');
    }
  };

  const onClick = () => {
    setLoading(true);
    // All redirect url should be registered in Stytch dashboard
    const redirectUrl = isChromeExtApp()
      ? chrome.identity.getRedirectURL()
      : `${window.location.origin}${URL_PATHNAMES.HOME}`;

    const extractToken = (url: string) => {
      const urlParams = new URLSearchParams(url);
      return urlParams.get('token');
    };

    const BASE_URL = isSopai()
      ? GOOGLE_AUTH_BASE_URL
      : GOOGLE_AUTH_B2B_BASE_URL;
    if (isChromeExtApp()) {
      getPublicToken(String(extensionName))
        .then((token: string) => {
          if (token) {
            const publicToken = token;

            let url;
            if (isSopai()) {
              url = `${BASE_URL}?public_token=${publicToken}&login_redirect_url=${redirectUrl}&provider_prompt=select_account&signup_redirect_url=${redirectUrl}`;
            } else {
              url = `${BASE_URL}?public_token=${publicToken}&discovery_redirect_url=${redirectUrl}&provider_prompt=select_account`;
            }

            chrome.identity
              .launchWebAuthFlow({
                url: url,
                interactive: true
              })
              .then((responseUrl?: string) => {
                if (!responseUrl) return;
                onSignIn(extractToken(responseUrl) as string);
              })
              .catch((error) => {
                console.log(error);
                if (
                  error.message !== 'Authorization page could not be loaded.' &&
                  !error.message.includes('The user did not approve access')
                ) {
                  setUnregisteredChromeAppId(true);
                }
                setLoading(false);
              });
          } else {
            setLoading(false);
            showSnackbar('Sign in failed, please try again later', 'error');
          }
        })
        .catch((error) => {
          setLoading(false);
          showSnackbar(`Error: ${error}`, 'error');
        });
    } else {
      // Flag to see if user closed popup preemptively without going thru login process.
      // This includes creating new account process but getting the 'unauthorized' view
      let popupClosedPreemptively = true;
      const publicToken = process.env.REACT_APP_GOOGLE_PUBLIC_TOKEN;

      let url;
      if (isSopai()) {
        url = `${BASE_URL}?public_token=${publicToken}&login_redirect_url=${redirectUrl}&provider_prompt=select_account&signup_redirect_url=${redirectUrl}`;
      } else {
        url = `${BASE_URL}?public_token=${publicToken}&discovery_redirect_url=${redirectUrl}&provider_prompt=select_account`;
      }

      const popup = window.open(url, 'popup', 'popup=true');
      const checkPopup = setInterval(() => {
        try {
          // TODO: remove try catch, this happen when the URL is GDOCS url
          if (
            popup &&
            popup.window.location.href.includes(window.location.origin)
          ) {
            popupClosedPreemptively = false;
            onSignIn(extractToken(popup.window.location.href) as string);
            popup.close();
          }
        } catch (error) {
          // Entering this error is expected while the url is in the unexpected loc
        }

        if (!popup || !popup.closed) return;
        clearInterval(checkPopup);
        // Set loading false and show snackbar but does not throw error on bugsnag
        if (popupClosedPreemptively) {
          setLoading(false);
          showSnackbar('Failed to login', 'error');
        }
      }, 1000);
    }
  };

  const email = isSopai() ? 'support@sopai.ai' : 'support@dokai.ai';

  if (unregisteredChromeAppId) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '12px',
            textAlign: 'center'
          }}
        >
          Please send email with url below to {email} to get your extension
          registered.
        </Typography>
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '12px',
            textAlign: 'center'
          }}
        >
          {chrome.identity.getRedirectURL()}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        maxWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <img
        src={icon}
        alt="dokai-icon"
        style={{
          height: '48px',
          width: '48px'
        }}
      />
      <Typography
        component="div"
        sx={{
          margin: '6px',
          fontWeight: '600',
          lineHeight: '24px',
          fontSize: '16px'
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          Hi, {extensionName !== ExtensionName.SOPAI ? 'Dokai' : 'SOPAI'} here{' '}
          <WavingHandIcon
            sx={{
              color: theme.customColors.themeEmojiYellow,
              marginLeft: '5px',
              alignSelf: 'center'
            }}
          />
        </Box>
      </Typography>
      <Typography
        sx={{
          maxWidth: '300px',
          margin: '6px',
          mb: '24px',
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '18px',
          textAlign: 'center'
        }}
      >
        Unlock the full potential of your browsing experience with
        <span style={{ color: theme.customColors.themePrimary }}>
          {' '}
          AI-powered{' '}
        </span>
        insights and seamless assistance.
      </Typography>

      <EmailLoginTile
        loading={loading}
        onSubmit={() => {}}
        sx={{ mb: '10px' }}
      />

      <GoogleSigninButton
        loading={loading}
        onClick={onClick}
        sx={{ mb: '10px' }}
      />
      {/* <MicrosoftSigninButton loading={loading} onClick={onClick} /> */}
      <WelcomeModal
        modalTitle="No affiliated organization found"
        modalContent="Email support@dokai.io to register your company with us"
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </Box>
  );
};

export default LoginComponent;
