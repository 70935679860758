import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
  useCallback,
} from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  TextField,
  Menu,
  MenuItem,
  Modal
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid";
import { Add, DeleteOutlined, MoreVert } from "@mui/icons-material";
import { ExpectedInput } from "../../../types/automation";
import { useSnackbar } from "../../../context/SnackbarContext";

interface AutomationJobInputTableProps {
  inputSchema?: ExpectedInput[];
  setAcceptedRows: React.Dispatch<React.SetStateAction<Record<string, any>[]>>;
  onValidityChange?: (isValid: boolean) => void;
  onColumnsChange?: (cols: GridColDef[]) => void;
}

export interface AutomationJobInputTableRef {
  handleBulkAddRows: (newRows: Record<string, any>[]) => void;
  checkIncompleteRows: () => boolean;
}

type StatusType = "accepted" | "incomplete";

interface DataRow {
  id: string | number;
  status: StatusType;
  [key: string]: any;
}

const MAX_ROWS = 100;

const AutomationJobInputTable = forwardRef<
  AutomationJobInputTableRef,
  AutomationJobInputTableProps
>(({ inputSchema, setAcceptedRows, onValidityChange, onColumnsChange }, ref) => {
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rows, setRows] = useState<DataRow[]>([]);
  const [newColumnName, setNewColumnName] = useState("");
  const [columnDialogOpen, setColumnDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [columnToRename, setColumnToRename] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentColumnField, setCurrentColumnField] = useState<string | null>(
    null
  );
  const { showSnackbar } = useSnackbar();
  const protectedFields = (inputSchema ?? []).map((item) => item.name);
  const isProtectedField = (fieldName: string) => protectedFields.includes(fieldName);

  const handleColumnMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    field: string
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentColumnField(field);
  };

  const handleColumnMenuClose = () => {
    setAnchorEl(null);
    setCurrentColumnField(null);
  };

  const handleRenameColumnClick = () => {
    if (!currentColumnField) return;
    setRenameDialogOpen(true);
    setColumnToRename(currentColumnField);
    setNewColumnName(currentColumnField);
    handleColumnMenuClose();
  };

  const handleRemoveColumnClick = () => {
    if (!currentColumnField) return;
    if (isProtectedField(currentColumnField)) {
      showSnackbar("Cannot remove required column", "error");
      return;
    }
    const removedField = currentColumnField;

    const updatedCols = columns.filter((col) => col.field !== removedField);

    setColumns(updatedCols);

    setRows((prev) =>
      prev.map((row) => {
        const updatedRow = { ...row };
        delete updatedRow[removedField];
        updatedRow.status = isRowComplete(updatedRow, updatedCols)
          ? "accepted"
          : "incomplete";
        return updatedRow;
      })
    );

    handleColumnMenuClose();
  };

  const handleRenameConfirm = () => {
    if (!columnToRename) return;
    if (isProtectedField(columnToRename)) {
      showSnackbar("Cannot rename required column", "error");
      return;
    }
    const trimmedName = newColumnName.trim();

    if (!trimmedName) {
      showSnackbar("Column name cannot be empty", "error");
      return;
    }

    const oldField = columnToRename;
    const newField = newColumnName.trim();

    if (columns.some((c) => c.field === newField && c.field !== oldField)) {
      showSnackbar("Column name already exists", "error");
      return;
    }

    const updatedCols: GridColDef[] = columns.map((col) => {
      if (col.field === oldField) {
        return {
          ...col,
          field: newField,
          headerName: newField,
        };
      }
      return col;
    });

    setColumns(updatedCols);

    setRows((prev) =>
      prev.map((row) => {
        if (!(oldField in row)) {
          return row;
        }
        const updatedRow = { ...row, [newField]: row[oldField] };
        delete updatedRow[oldField];
        updatedRow.status = isRowComplete(updatedRow, updatedCols)
          ? "accepted"
          : "incomplete";
        return updatedRow;
      })
    );

    setRenameDialogOpen(false);
    setNewColumnName("");
  };

  const initializeColumns = useCallback(() => {
    if (!inputSchema) return;
    const initialCols: GridColDef[] = inputSchema.map((schema) => ({
      field: schema.name,
      headerName: schema.name,
      editable: true,
      flex: 1,
    }));

    setColumns([...initialCols]);
  }, [inputSchema]);

  useImperativeHandle(ref, () => ({
    handleBulkAddRows(newRows: Record<string, any>[]) {
      const totalCount = rows.length + newRows.length;
      if (totalCount > MAX_ROWS) {
        showSnackbar(`Cannot add more than ${MAX_ROWS} rows.`, 'error');
        return;
      }
      const combined: DataRow[] = [...rows];
      newRows.forEach((row) => {
        const isComplete = isRowComplete(row, columns);
        combined.push({
          id: Math.random(),
          status: isComplete ? 'accepted' : 'incomplete',
          ...row,
        });
      });
      setRows(combined);
    },
    checkIncompleteRows() {
      const hasIncomplete = rows.some((r) => r.status === "incomplete");
      return hasIncomplete;
    }
  }));

  const isRowComplete = (
    row: Record<string, any>,
    colDefs: GridColDef[]
  ): boolean => {
    const dataCols = colDefs.filter(
      (c) => c.field !== "status" && c.field !== "id" && c.field !== "actions"
    );
    return dataCols.every((c) => {
      const val = row[c.field];
      return val !== undefined && String(val).trim() !== "";
    });
  };

  const handleAddRow = () => {
    if (rows.length >= MAX_ROWS) {
      showSnackbar(`Cannot add more than ${MAX_ROWS} rows.`, "error");
      return;
    }
    const base: any = {};
    columns.forEach((col) => {
      if (col.field !== "status" && col.field !== "id") {
        base[col.field] = "";
      }
    });
    setRows((prev) => [
      ...prev,
      {
        id: Math.random(),
        status: "incomplete",
        ...base,
      },
    ]);
  };

  const handleAddColumn = () => {
    const trimmedName = newColumnName.trim();

    if (!trimmedName) {
      showSnackbar("Column name cannot be empty", "error");
      return;
    }

    if (columns.some((col) => col.field === trimmedName)) {
      showSnackbar("Column name already exists", "error");
      return;
    }

    const updatedCols: GridColDef[] = [
      ...columns.filter((c) => c.field !== "status"),
      {
        field: trimmedName,
        headerName: trimmedName,
        editable: true,
        flex: 1,
      },
      ...columns.filter((c) => c.field === "status"),
    ];

    setColumns(updatedCols);

    setRows((prev) =>
      prev.map((row) => ({
        ...row,
        [trimmedName]: "",
        status: isRowComplete(row, updatedCols) ? "accepted" : "incomplete",
      }))
    );
  
    setColumnDialogOpen(false);
    setNewColumnName("");
  };

  const processRowUpdate = (newRow: DataRow, oldRow: DataRow) => {
    const updated = { ...newRow };
    updated.status = isRowComplete(updated, columns) ? 'accepted' : 'incomplete';
    setRows((prev) =>
      prev.map((r) => (r.id === oldRow.id ? updated : r))
    );
    return updated;
  };

  const handleProcessRowUpdateError = (error: any) => {
    showSnackbar("Row Update Error: " + error, "error");
  };

  const handleDeleteRow = (id: GridRowId) => {
    setRows((prev) => prev.filter((row) => row.id !== id));
  };

  const extendedColumns: GridColDef[] = [
    ...columns.map((col) => {
      if (col.field === "status") {
        return col;
      }
      return {
        ...col,
        renderHeader: () => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              <span>{col.headerName}</span>
              {col.field !== "actions" && col.field !== "status" && !isProtectedField(col.field) && (
                <IconButton
                  size="small"
                  onClick={(e) => handleColumnMenuOpen(e, col.field)}
                >
                  <MoreVert fontSize="small" />
                </IconButton>
              )}
            </Box>
          );
        },
      };
    }),
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleDeleteRow(params.row.id)}>
          <DeleteOutlined
            sx={(theme) => ({
              color: theme.customColors.themeDanger,
            })}
          />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    if (inputSchema) {
      initializeColumns();
      setRows([]);
    }
  }, [inputSchema, initializeColumns]);

  useEffect(() => {
    const accepted = rows.filter((r) => r.status === "accepted").map(({ status, id, ...rest }) => rest);
    setAcceptedRows(accepted);
    const hasIncomplete = rows.some((r) => r.status === "incomplete");
    onValidityChange?.(!hasIncomplete && rows.length > 0);
  }, [rows, setAcceptedRows, onValidityChange]);

  useEffect(() => {
    onColumnsChange?.(columns);
  }, [columns, onColumnsChange]);

  return (
    <Box
      flex={1}
      overflow="auto"
      my={1}
      sx={{
        maxHeight: "100%",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      {!columns.length ? (
        <Typography variant="h6" align="center" sx={{ my: 2 }}>
          No schema provided.
        </Typography>
      ) : (
        <>
          <Box mb={1} display="flex" gap={1} flexDirection="row">
            <Button
              variant="text"
              sx={(theme) => ({
                textTransform: "capitalize",
                color: theme.customColors.themeNeutralPrimaryColor,
              })}
              onClick={handleAddRow}
              disabled={rows.length >= MAX_ROWS}
            >
              <Add /> Click to add row
            </Button>
            <Button
              variant="text"
              sx={(theme) => ({
                textTransform: "capitalize",
                color: theme.customColors.themeNeutralPrimaryColor,
              })}
              onClick={() => setColumnDialogOpen(true)}
            >
              <Add /> Add Column
            </Button>
          </Box>
          <Box sx={{ height: 'auto', width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={extendedColumns}
              disableRowSelectionOnClick
              disableColumnSorting
              disableColumnMenu
              editMode="cell"
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              getRowClassName={(params) =>
                params.row.status === "accepted"
                  ? "accepted-row"
                  : "incomplete-row"
              }
            />
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleColumnMenuClose}
          >
            <MenuItem onClick={handleRenameColumnClick}>Rename</MenuItem>
            <MenuItem onClick={handleRemoveColumnClick}>Remove</MenuItem>
          </Menu>

          <Modal open={renameDialogOpen} onClose={() => setRenameDialogOpen(false)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: '8px'
              }}
            >
              <Typography variant="h6" gutterBottom>
                Rename Column
              </Typography>
              <TextField
                label="New Column Name"
                variant="outlined"
                value={newColumnName}
                sx={{ width: '100%', marginY: '16px' }}
                onChange={(e) => setNewColumnName(e.target.value)}
              />
              <Box display="flex" justifyContent="space-between" mt={2} gap={1}>
                <Button 
                  sx={(theme) => ({
                    width: '50%',
                    border: `1px solid ${theme.customColors.themePrimary}`,
                    backgroundColor: theme.customColors.themeSecondary,
                    color: theme.customColors.themePrimary,
                    borderRadius: '50px',
                    textTransform: 'capitalize'
                  })}
                  onClick={() => setRenameDialogOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleRenameConfirm}
                  disabled={!newColumnName.trim()}
                  sx={(theme) => ({
                    width: '50%',
                    border: `1px solid ${theme.customColors.themePrimary}`,
                    backgroundColor: theme.customColors.themePrimary,
                    color: theme.customColors.themeSecondary,
                    borderRadius: '50px',
                    textTransform: 'capitalize',
                    '&:disabled': {
                      border: `1px solid ${theme.customColors.themeNeutralSecondaryColor}`,
                      backgroundColor: theme.customColors.themeNeutralSecondaryColor,
                      color: theme.customColors.themeSecondary,
                    }
                  })}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}

      <Modal open={columnDialogOpen} onClose={() => setColumnDialogOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px'
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add a New Column
          </Typography>
          <TextField
            label="Column Name"
            variant="outlined"
            value={newColumnName}
            sx={{ width: '100%', marginY: '16px' }}
            onChange={(e) => setNewColumnName(e.target.value)}
          />
          <Box display="flex" justifyContent="space-between" mt={2} gap={1}>
            <Button
              onClick={() => setColumnDialogOpen(false)}
              sx={(theme) => ({
                width: '50%',
                border: `1px solid ${theme.customColors.themePrimary}`,
                backgroundColor: theme.customColors.themeSecondary,
                color: theme.customColors.themePrimary,
                borderRadius: '50px',
                textTransform: 'capitalize'
              })}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleAddColumn}
              disabled={!newColumnName.trim()}
              sx={(theme) => ({
                width: '50%',
                border: `1px solid ${theme.customColors.themePrimary}`,
                backgroundColor: theme.customColors.themePrimary,
                color: theme.customColors.themeSecondary,
                borderRadius: '50px',
                textTransform: 'capitalize',
                '&:disabled': {
                  border: `1px solid ${theme.customColors.themeNeutralSecondaryColor}`,
                  backgroundColor: theme.customColors.themeNeutralSecondaryColor,
                  color: theme.customColors.themeSecondary,
                }
              })}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
});

export default AutomationJobInputTable;
