// src/Layout.tsx
import { Outlet, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import Sidebar from '../SideBar/SideBar';
import { useUserContextController } from '../../context/UserContext';

// For different justify content value of window content, add URL and also the justify content value in the object below
export const URL_PATHNAMES = {
  HOME: '/home',
  GUIDE: '/guide',
  SETTINGS: '/settings',
  AGENT_DASHBOARD: '/profile/agent-dashboard',
  DOCUMENTATION_LIST: '/documentation/list',
  DOCUMENTATION_DETAILS: '/documentation/details',
  TEACH: '/teach',
  AUTOMATION: '/automation',
  AUTOMATION_JOB: 'automation/job',
  AUTOMATION_INPUT: 'automation/input',
  AUTOMATION_OUTPUT: 'automation/output',
  AUTOMATION_CREATE: 'automation/create',
  WORKFLOW: '/workflow',
  AI_FLEET: '/ai-fleet'
};

const justifyContentValue = {
  [URL_PATHNAMES.HOME]: 'start',
  [URL_PATHNAMES.GUIDE]: 'end',
  [URL_PATHNAMES.SETTINGS]: 'start',
  [URL_PATHNAMES.AGENT_DASHBOARD]: 'start',
  [URL_PATHNAMES.DOCUMENTATION_LIST]: 'start',
  [URL_PATHNAMES.TEACH]: 'start',
  [URL_PATHNAMES.AUTOMATION]: 'start',
  [URL_PATHNAMES.AUTOMATION_JOB]: 'start',
  [URL_PATHNAMES.AUTOMATION_INPUT]: 'start',
  [URL_PATHNAMES.AUTOMATION_OUTPUT]: 'start',
  [URL_PATHNAMES.AUTOMATION_CREATE]: 'start',
  [URL_PATHNAMES.AI_FLEET]: 'start',
  [URL_PATHNAMES.WORKFLOW]: 'start'
};

interface LayoutProps {
  isWebApp: boolean;
}

const Layout = (props: LayoutProps) => {
  const [userContext] = useUserContextController();
  const location = useLocation();
  const { user } = userContext;
  const justify =
    location.pathname in justifyContentValue
      ? justifyContentValue[location.pathname]
      : 'start';

  return user ? (
    <>
      {!location.pathname.match(URL_PATHNAMES.DOCUMENTATION_DETAILS) &&
        props.isWebApp && (
          <Sidebar
            isWebApp={props.isWebApp}
            isManager={user.isManager}
            hasAccessToAutomation={user.hasAccessToAutomation}
          />
        )}
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: `${justify}`,
          backgroundColor: theme.customColors.themeSecondary,
          boxSizing: 'border-box',
          width: !location.pathname.match(URL_PATHNAMES.DOCUMENTATION_DETAILS)
            ? `calc(100% - ${props.isWebApp ? '150px' : '50px'})`
            : '100%',
          margin: `10px
            ${
              !location.pathname.match(URL_PATHNAMES.DOCUMENTATION_DETAILS) &&
              !props.isWebApp
                ? '0'
                : '10px'
            }
            10px
            ${
              !location.pathname.match(URL_PATHNAMES.DOCUMENTATION_DETAILS) &&
              props.isWebApp
                ? '0'
                : '10px'
            }
          `,
          padding: '0px',
          borderRadius: '15px',
          overflow:
            location.pathname.match(URL_PATHNAMES.SETTINGS) ||
            location.pathname.match(URL_PATHNAMES.WORKFLOW)
              ? 'auto'
              : 'hidden',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        })}
      >
        <Outlet />
      </Box>
      {!location.pathname.match(URL_PATHNAMES.DOCUMENTATION_DETAILS) &&
        !props.isWebApp && (
          <Sidebar
            isWebApp={props.isWebApp}
            isManager={user.isManager}
            hasAccessToAutomation={user.hasAccessToAutomation}
          />
        )}
    </>
  ) : (
    <Outlet />
  );
};

export default Layout;
