import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { SALESFORCE_AUTH_BASE_URL } from '../../api/constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { GOOGLE_ACCOUNT_TYPE } from '../../types/userCredentials';
import {
  setSalesforceCredentials,
  useUserContextController
} from '../../context/UserContext';
import { setCredentials } from '../../api/userProfile';
import { useSnackbar } from '../../context/SnackbarContext';
import { useState } from 'react';
import SalesforceLogo from '../../assets/SalesforceLogo.svg';
import Bugsnag from '@bugsnag/js';
import { URL_PATHNAMES } from '../layout';

interface SalesforceAuthComponentProps {
  salesforceAuthToken: string | undefined;
  userId: string;
}

enum EnumMessageType {
  SUCCESS = 'SUCCESS',
  FAILED_CREDENTIALS = 'FAILED_CREDENTIALS',
  FAILED_AUTH = 'FAILED_AUTH',
  PREEMPTIVE = 'PREEMPTIVE'
}

const linkAccountSnackbarMessages = {
  [EnumMessageType.SUCCESS]: 'Login to Salesforce successful',
  [EnumMessageType.FAILED_CREDENTIALS]: 'Failed to link Salesforce account',
  [EnumMessageType.FAILED_AUTH]: 'Failed to link Salesforce account',
  [EnumMessageType.PREEMPTIVE]: 'Failed to link Salesforce account'
};

const SalesforceAuthComponent = (props: SalesforceAuthComponentProps) => {
  const [loading, setLoading] = useState(false);
  const [, userDispatch] = useUserContextController();
  const { showSnackbar } = useSnackbar();
  const messageSettings = linkAccountSnackbarMessages;

  /**
   * onSignIn: Called when a valid access token is received.
   * Stores the token (and instance URL if provided)
   */
  const onSignIn = (accessToken: string, instanceUrl?: string) => {
    if (accessToken) {
      const accountType = GOOGLE_ACCOUNT_TYPE.WEB_APP;
      const userCredentials = {
        user_id: String(props.userId),
        credentials: {
          salesforce: {
            access_token: accessToken,
            instance_url: instanceUrl
          }
        }
      };
      setCredentials(accountType, userCredentials)
        .then(() => {
          showSnackbar(messageSettings.SUCCESS, 'success');
          setSalesforceCredentials(userDispatch, {
            access_token: accessToken,
            instance_url: instanceUrl
          });
        })
        .catch(() => {
          Bugsnag.notify(
            'Credentials Error: ' + messageSettings.FAILED_CREDENTIALS
          );
          showSnackbar(messageSettings.FAILED_CREDENTIALS, 'error');
          setLoading(false);
        });
    } else {
      Bugsnag.notify('Auth Error: ' + messageSettings.FAILED_AUTH);
      showSnackbar(messageSettings.FAILED_AUTH, 'error');
      setLoading(false);
    }
  };

  const onClick = () => {
    setLoading(true);
    const REDIRECT_URI = `${window.location.origin}${URL_PATHNAMES.HOME}`;
    const clientId = process.env.REACT_APP_SALESFORCE_CLIENT_ID;
    const url = `${SALESFORCE_AUTH_BASE_URL}?response_type=token&client_id=${clientId}&redirect_uri=${REDIRECT_URI}`;

    const extractTokenData = (url: string) => {
      const hash = new URL(url).hash;
      const params = new URLSearchParams(hash.substring(1));
      return {
        accessToken: params.get('access_token') || '',
        instanceUrl: params.get('instance_url') || ''
      };
    };

    const popup = window.open(url, 'popup', 'popup=true');
    const checkPopup = setInterval(() => {
      let popupClosedPreemptively = true;
      try {
        if (
          popup &&
          popup.location &&
          popup.location.href.includes(window.location.origin)
        ) {
          const { accessToken, instanceUrl } = extractTokenData(
            popup.location.href
          );
          popupClosedPreemptively = false;
          onSignIn(accessToken, instanceUrl);
          popup.close();
        }
      } catch (error) {
        // Expected: Cross-origin restrictions may cause errors until the redirect happens.
      }
      if (!popup || popup.closed) {
        clearInterval(checkPopup);
        if (popupClosedPreemptively) {
          setLoading(false);
          showSnackbar(messageSettings.PREEMPTIVE, 'error');
        }
      }
    }, 1000);
  };

  return (
    <Box
      sx={(theme) => ({
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        border: `1px solid ${theme.customColors.themeNeutralSecondaryColor}`,
        justifyContent: 'space-between',
        marginBottom: '20px',
        borderRadius: '8px',
        width: '100%',
        padding: '20px 16px',
        textAlign: 'left',
        textTransform: 'capitalize',
        color: theme.customColors.themeNeutralPrimaryColor
      })}
    >
      <Box sx={{ display: 'flex', justifyContent: 'start' }}>
        <img
          alt="salesforce-logo"
          src={SalesforceLogo}
          style={{
            height: '20px',
            width: '20px',
            marginRight: '10px',
            fontSize: '20px',
            alignSelf: 'center'
          }}
        />
        <Typography sx={{ alignSelf: 'center', fontWeight: '600' }}>
          Salesforce
        </Typography>
        {props.salesforceAuthToken && (
          <CheckCircleIcon
            sx={(theme) => ({
              marginLeft: '4px',
              width: '16px',
              height: '16px',
              color: theme.customColors.themeSuccess,
              alignSelf: 'center'
            })}
          />
        )}
      </Box>
      {!props.salesforceAuthToken && (
        <Button
          sx={(theme) => ({
            boxSizing: 'border-box',
            padding: '0',
            textAlign: 'left',
            textTransform: 'capitalize',
            fontWeight: '600',
            fontSize: '12px',
            color: theme.customColors.themePrimary,
            ':hover': { background: 'none' }
          })}
          onClick={onClick}
          disabled={loading}
          endIcon={loading ? <CircularProgress size={12} /> : null}
        >
          Link Account
        </Button>
      )}
    </Box>
  );
};

export default SalesforceAuthComponent;
