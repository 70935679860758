import React, { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';

interface AvatarGeneratorProps {
  fullname: string;
  size?: number;
  imageUrl?: string | null;
}

const AvatarGenerator: React.FC<AvatarGeneratorProps> = ({ fullname, size = 28, imageUrl }) => {
  const theme = useTheme();
  const textColor = theme.palette.common.white;

  const getInitials = (name: string) => {
    if (!name.trim()) return '?';

    const parts = name.trim().split(/\s+/);
    const firstInitial = parts[0]?.charAt(0).toUpperCase() || '';
    const lastInitial = parts.length > 1 ? parts[parts.length - 1].charAt(0).toUpperCase() : '';

    return firstInitial + lastInitial;
  };

  const initials = useMemo(() => getInitials(fullname), [fullname]);

  const generateBackgroundColor = (name: string) => {
    const hash = Array.from(name).reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const hue = (hash * 37) % 360;
    const saturation = 60 + (hash % 40);
    const lightness = 30 + (hash % 20);
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const backgroundColor = useMemo(() => generateBackgroundColor(fullname), [fullname]);

  const avatarUrl = useMemo(() => {
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext('2d');
    if (!ctx) return '';

    ctx.fillStyle = backgroundColor;
    ctx.beginPath();
    ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
    ctx.fill();

    ctx.fillStyle = textColor;
    ctx.font = `${size * 0.4}px Arial, sans-serif`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(initials, size / 2, size / 2 + (size * 0.02));

    return canvas.toDataURL();
  }, [initials, size, backgroundColor, textColor]);

  const isValidImageUrl = imageUrl && imageUrl.trim() !== '';

  return (
    <img
      src={isValidImageUrl ? imageUrl : avatarUrl}
      alt={fullname}
      width={size}
      height={size}
      referrerPolicy="no-referrer" // no-referrer needs to be stated in order to load google hosted images
      title={fullname}
      style={{
        borderRadius: '50%',
        objectFit: 'cover',
        verticalAlign: 'middle',
        marginRight: '4px',
      }}
      onError={(e) => {
        (e.target as HTMLImageElement).src = avatarUrl;
      }}
    />
  );
};

export default AvatarGenerator;
