// Only change the following constants
export var env = 'prod';
// export var env = 'local';

// Dont touch the following constants, we use constant because chrome extension is not able to read env variables
const isProd = env === 'prod';
export const BASE_URL = isProd
  ? 'https://patia-ai-5d4eafb83866.herokuapp.com'
  : 'http://localhost:8000';
export const WEB_BASE_URL = isProd
  ? 'https://app.dokai.ai'
  : 'http://localhost:3000';
export const SOPAI_WEB_BASE_URL = isProd
  ? 'https://app.sopai.io'
  : 'http://localhost:3001';
export const SLACK_AUTH_BASE_URL = 'https://slack.com/oauth/v2/authorize';
export const GOOGLE_AUTH_BASE_URL = isProd
  ? 'https://api.stytch.com/v1/public/oauth/google/start'
  : 'https://test.stytch.com/v1/public/oauth/google/start';
export const GOOGLE_AUTH_B2B_BASE_URL = isProd
  ? 'https://api.stytch.com/v1/b2b/public/oauth/google/discovery/start'
  : 'https://test.stytch.com/v1/b2b/public/oauth/google/discovery/start';
// export const STYTCH_SLACK_AUTH_BASE_URL = 'https://test.stytch.com/v1/public/oauth/slack/start';
export const NOTION_AUTH_BASE_URL = 'https://api.notion.com/v1/oauth/authorize';
export const GOOGLE_API_CUSTOM_SCOPE =
  'https://www.googleapis.com/auth/drive.readonly';
export const SLACK_USER_SCOPE =
  'users:read,users:read.email,channels:read,channels:history';
export const DEFAULT_AGENT_NAME = '';
export const CHROME_EXTENSION_ID = 'bknpanghphmdmlcpdbebcdekgghmohbl';
export const CHROME_EXTENSION_URL = `https://chromewebstore.google.com/detail/dokai-beta/${CHROME_EXTENSION_ID}`;
export const SALESFORCE_AUTH_BASE_URL =
  'https://login.salesforce.com/services/oauth2/authorize';
