import { createContext, useContext, useState, ReactNode } from 'react';
import { AutomationJob, WorkflowDetail } from '../types/automation';

interface AutomationContextProps {
  job: AutomationJob | null;
  workflow: WorkflowDetail | null;
  setJob: (job: AutomationJob) => void;
  setWorkflow: (workflow: WorkflowDetail) => void;
}

const AutomationContext = createContext<AutomationContextProps | undefined>(undefined);

export const AutomationProvider = ({ children }: { children: ReactNode }) => {
  const [job, setJob] = useState<AutomationJob | null>(null);
  const [workflow, setWorkflow] = useState<WorkflowDetail | null>(null);

  return (
    <AutomationContext.Provider value={{ job, workflow, setJob, setWorkflow }}>
      {children}
    </AutomationContext.Provider>
  );
};

export const useAutomation = () => {
  const context = useContext(AutomationContext);
  if (!context) {
    throw new Error('useAutomation must be used within an AutomationProvider');
  }
  return context;
};
