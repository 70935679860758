import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';

interface ConfirmationDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title?: string;
  content?: string;
  cancelText?: string;
  confirmText?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onCancel,
  onConfirm,
  title = 'Confirm Action',
  content = 'Are you sure you want to perform this action?',
  cancelText = 'Cancel',
  confirmText = 'Confirm'
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'block',
          padding: '16px 24px'
        }}
      >
        <Button
          variant="contained"
          onClick={onConfirm}
          sx={(theme) => ({
            boxSizing: 'border-box',
            border: `1px solid ${theme.customColors.themePrimary}`,
            backgroundColor: theme.customColors.themePrimary,
            color: theme.customColors.themeSecondary,
            display: 'block',
            width: '100%',
            margin: '0',
            textTransform: 'capitalize'
          })}
        >
          {confirmText}
        </Button>
        <Button
          onClick={onCancel}
          sx={(theme) => ({
            boxSizing: 'border-box',
            border: `1px solid ${theme.customColors.themePrimary}`,
            backgroundColor: theme.customColors.themeSecondary,
            color: theme.customColors.themePrimary,
            display: 'block',
            width: '100%',
            margin: '10px 0 0 0 !important',
            textTransform: 'capitalize'
          })}
        >
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
