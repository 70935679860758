import { BASE_URL } from './constants';
import { getUserSessionCookie } from '../utils/SessionCookie';

export async function createAutomationJob(
  job_description: string
): Promise<any> {
  const url = `${BASE_URL}/automation/create`;
  const userSessionToken = getUserSessionCookie();
  const requestData = {
    job_description,
    extra_data: {}
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userSessionToken}`
      },
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error creating automation job:', error);
    throw error;
  }
}

export async function getAutomationJob(jobId: string): Promise<any> {
  const url = `${BASE_URL}/automation/${jobId}`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userSessionToken}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error getting automation job:', error);
    throw error;
  }
}

export async function getAutomationJobs(
  offset: number,
  limit: number
): Promise<any> {
  const url = `${BASE_URL}/automation?offset=${offset}&limit=${limit}`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userSessionToken}`
      }
    });

    return await response.json();
  } catch (error) {
    console.error('Error getting automation jobs:', error);
    throw error;
  }
}

export async function getAutomationJobsSummaryV2(
  offset: number,
  limit: number
): Promise<any> {
  const url = `${BASE_URL}/v2/automation/company?offset=${offset}&limit=${limit}`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userSessionToken}`
      }
    });

    return await response.json();
  } catch (error) {
    console.error('Error getting automation jobs:', error);
    throw error;
  }
}

export async function getAutomationJobV2(jobId: string): Promise<any> {
  const url = `${BASE_URL}/v2/automation/${jobId}`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userSessionToken}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error getting automation job:', error);
    throw error;
  }
}

export async function createAutomationJobV2(jobData: {
  job_description: string;
  workflow_id: string;
  input_data?: any;
  extra_data: any;
}): Promise<any> {
  const url = `${BASE_URL}/v2/automation/create`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userSessionToken}`
      },
      body: JSON.stringify(jobData)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error creating automation job:', error);
    throw error;
  }
}

export async function uploadInputFile(jobId: string, file: File): Promise<any> {
  const url = `${BASE_URL}/v2/automation/${jobId}/upload_inputfile`;
  const userSessionToken = getUserSessionCookie();

  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userSessionToken}`
      },
      body: formData
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error uploading input file:', error);
    throw error;
  }
}

export async function downloadOutputFile(jobId: string): Promise<Blob> {
  const url = `${BASE_URL}/v2/automation/${jobId}/get_outputfile`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userSessionToken}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.blob();
  } catch (error) {
    console.error('Error downloading output file:', error);
    throw error;
  }
}

export async function downloadInputFile(jobId: string): Promise<Blob> {
  const url = `${BASE_URL}/v2/automation/${jobId}/get_inputfile`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userSessionToken}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.blob();
  } catch (error) {
    console.error('Error downloading input file:', error);
    throw error;
  }
}
export async function startJob(jobId: string): Promise<any> {
  const url = `${BASE_URL}/v2/automation/${jobId}/start`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userSessionToken}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error starting job:', error);
    throw error;
  }
}

export async function getOutputFileUrl(s3Key: string): Promise<string> {
  const url = `${BASE_URL}/v2/automation/get-outputfile-url?s3_key=${encodeURIComponent(s3Key)}`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userSessionToken}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // If your BE returns a plain JSON string (e.g., "https://..."), use response.json()
    // If it returns a plain text, then use response.text()
    const presignedUrl: string = await response.json();
    return presignedUrl;
  } catch (error) {
    console.error('Error getting output file URL:', error);
    throw error;
  }
}
